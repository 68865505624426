import React from 'react';
import ReactMarkdown from "react-markdown";
import TextareaAutosize from 'react-textarea-autosize';
import classnames from 'classnames';
import marked from 'marked';
import axios from 'axios';

import './App.css';

const LOCALSTORAGE_KEY = 'write:state';
const DEFAULT_STATE = {
	text: '# Write\n\nclean, simple and distraction-free writing environment\n\n>A minimal interface that keeps your focus on what you’re writing. Feels like you’re writing on a clean sheet of paper without any of the usual distractions found with word processors.\n\n## Features\n\n- Support Standard Markdown\n- Compatible with all major browsers and devices\n- Free and easy to use',
	preview: false,
	darkMode: false,
	drawer: true,
};

class App extends React.Component {
	constructor(props) {
		super(props);

		try {
			this.state = localStorage.getItem(LOCALSTORAGE_KEY) ?
				{ ...DEFAULT_STATE, ...JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) } :
				DEFAULT_STATE
		} catch (e) {
			this.state = DEFAULT_STATE;
		}

		this.fileSelector = React.createRef();
	}

	renderHeaders = () => {
		if (!this.state.text) {
			return null;
		}

		const tokens = marked.lexer(this.state.text);

		return tokens.map((token, index) => {
			switch (token.type) {
				case "heading":
					return <p key={`heading-${index}`}
						className={classnames(
							'heading',
							token.depth === 1 && 'heading-top',
						)}
						style={{ marginLeft: (token.depth - 1) * 15 }}>
						{token.text}
					</p>;
				default:
					return false;
			}
		})
	}

	handleChange = (event) => {
		this.setState({
			text: event.target.value,
		}, this.updateStore);
	}

	togglePreview = () => {
		this.setState((state) => ({
			preview: !state.preview
		}));
	}

	toggleDarkMode = () => {
		this.setState((state) => ({
			darkMode: !state.darkMode
		}), this.updateStore);
	}

	toggleDrawer = () => {
		this.setState((state) => ({
			drawer: !state.drawer
		}), this.updateStore);
	}

	newPage = () => {
		const okay = window.confirm(
			`Confirm deletion \n\nAre you sure you want to create new page? You won't be able to recover current page.`
		);

		if (!okay) return;

		this.setState({
			text: '',
		}, this.updateStore);
	}

	downloadFile = () => {
		if (!this.state.text) {
			return window.alert('Oops! Looks like the page is empty.');
		}

		const a = document.createElement('a');
		const blob = new Blob([this.state.text], { 'type': 'text/md' });
		a.href = window.URL.createObjectURL(blob);
		a.download = 'write.md';
		a.click();
	}

	openFile = (e) => {
		e.preventDefault();
		this.fileSelector.current.click();
	}

	onFileLoad = (e) => {
		e.preventDefault();

		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			this.setState({
				text: reader.result
			}, this.updateStore);
		}

		reader.readAsText(file);
	}

	updateStore = () => {
		if (window.localStorage) {
			localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(this.state));
		}
	}

	saveToDropbox = () => {
		if (!this.state.text) {
			return window.alert('Oops! Looks like the page is empty.');
		}

		const options = {
			success: function () {
				alert("Success! Files saved to your Dropbox.");
			},
			error: function (errorMessage) {
				alert("Oops! Failed to save file.");
			}
		};
		window.Dropbox.save(`data:text/md;base64,${btoa(unescape(encodeURIComponent(this.state.text)))}`, 'write.md', options);
	}

	openFromDropbox = () => {
		const that = this;
		const options = {
			// Required. Called when a user selects an item in the Chooser.
			success: function (files) {
				if (files.length) {
					axios
						.get(files[0].link)
						.then((response) => {
							if (response.data) {
								that.setState({
									text: response.data,
								}, that.updateStore);
							}
						})
						.catch((error) => {
							console.log(error)
							alert("Oops! Failed to open file.");
						})
				}
			},
			linkType: "direct", // or "direct"
			multiselect: false, // or true
			extensions: ['.txt', '.md'],
		};
		window.Dropbox.choose(options);
	}

	render() {
		const {
			text,
			preview,
			darkMode,
			drawer
		} = this.state;

		return (
			<div className={classnames(
				'container',
				darkMode ? 'container-dark' : 'container-light'
			)}>
				<form encType="multipart/form-data">
					<input
						style={{ display: 'none' }}
						ref={this.fileSelector}
						type='file'
						accept="text/plain, text/markdown"
						onChange={this.onFileLoad} />
				</form>

				<div className="toolbar"
					style={{
						marginLeft: !drawer ? 0 : 200
					}}>
					<button
						title={"new file"}
						onClick={this.newPage}>
						new file
					</button>
					<button
						title={"open file"}
						onClick={this.openFile}>
						open file
					</button>
					<button
						title={"save file"}
						onClick={this.downloadFile}>
						save file
					</button>

					<div className={
						classnames(
							"controls",
							darkMode ? 'container-dark' : 'container-light'
						)
					}>
						<p>{text ? text.split(' ').length : 0} words</p>
						<p>{text ? text.length : 0} characters</p>
					</div>
				</div>

				<div className="toolbar"
					style={{
						marginLeft: !drawer ? 0 : 200
					}}>
					<button
						className={"dropbox-action"}
						title={"save to dropbox"}
						onClick={this.saveToDropbox}>
						save to dropbox
					</button>
					<button
						className={"dropbox-action"}
						title={"save to dropbox"}
						onClick={this.openFromDropbox}>
						open from dropbox
					</button>
				</div>

				<div className="toolbar"
					style={{
						marginLeft: !drawer ? 0 : 200
					}}>
					<button
						title={"preview"}
						className={classnames(preview ? 'button-on' : 'button-off')}
						onClick={this.togglePreview}>
						preview
					</button>
					<button
						title={"toggle dark mode"}
						className={classnames(darkMode ? 'button-on' : 'button-off')}
						onClick={this.toggleDarkMode}>
						{darkMode ? 'light' : 'dark'} theme
					</button>
				</div>

				<div className="text-area"
					style={{
						marginLeft: !drawer ? 0 : 200
					}}>
					{preview ?
						<ReactMarkdown
							source={text}
							linkTarget={'_blank'}>
						</ReactMarkdown> :
						<TextareaAutosize
							placeholder={"Write..."}
							minRows={10}
							value={text}
							onChange={this.handleChange}>
						</TextareaAutosize>
					}
				</div>

				<div className={classnames(
					"drawer",
					darkMode ? "drawer-dark" : "drawer-light",
				)} style={{
					transform: !drawer ? `translate3d(-300px, 0px, 0px)` : `translate3d(0px, 0px, 0px)`
				}}>
					{this.renderHeaders()}
				</div>

				<button
					title={"toggle drawer"}
					className={"toggle-drawer"}
					onClick={this.toggleDrawer}>
					{drawer ? 'hide' : 'show'} drawer
				</button>
			</div>
		);
	}
}

export default App;
